.App {
  font-family: 'Lato', sans-serif;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

.font-cormorant {
  font-family: 'Cormorant Garamond', serif;
}